import React from "react";
import { Stack, Typography } from "@mui/material";
import XModal from "../../../../../components-v2/base-ui/XModal";
import XButton from "../../../../../components-v2/base-ui/XButton";

const SupportModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <XModal
      open={open}
      onClose={onClose}
      size="sm"
      header="Support Information"
      footer={<XButton onClick={onClose}>Close</XButton>}
    >
      <Stack gap={2}>
        <Typography variant="body1">
          For support with Reveald products and services:
        </Typography>
        <Stack gap={1} direction="row">
          <Typography variant="body2">1.</Typography>
          <Typography variant="body2">
            If you are a Reveald fusion center customer, create a ticket in
            Eiphany ensuring you select the “Sync with Reveald” option&nbsp;
            <a
              href="https://docs.epiphanysys.com/technical-documentation/admin-guides/epiphany-intelligence-platform-administrator-guide/epiphany-tools/tickets/reveald-ticket-synchronization"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white" }}
            >
              Reveald Ticket Synchronization | Technical Documentation
            </a>
          </Typography>
        </Stack>
        <Stack gap={1} direction="row">
          <Typography variant="body2">2.</Typography>
          <Typography variant="body2">
            You can call us on +1 800 844 6142. The support line is manned 08:00
            EST through 20:00 EST
          </Typography>
        </Stack>
        <Stack gap={1} direction="row">
          <Typography variant="body2">3.</Typography>
          <Typography variant="body2">
            You can email us at&nbsp;
            <a
              href="mailto:customer.support@reveald.com"
              title="mailto:customer.support@reveald.com"
              data-renderer-mark="true"
              style={{ color: "white" }}
            >
              customer.support@reveald.com
            </a>
          </Typography>
        </Stack>
      </Stack>
    </XModal>
  );
};

export default SupportModal;
