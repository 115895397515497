export enum EColumnType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  LINK = "LINK",
  DONUT = "DONUT",
  POPOVER = "POPOVER",
  CHML = "CHML", // Critical, High, Medium, Low,
  BOOL = "BOOL",
  ICON = "ICON",
  DATETIME = "DATETIME",
  CHIP = "CHIP",
  BALLCHIP = "BALLCHIP",
  AVATAR = "AVATAR",
  AVATARGROUP = "AVATARGROUP",
  ICONVALUE = "ICONVALUE",
}
