import React from "react";
import XAvatar from "../../../base-ui/XAvatar";
import { AvatarGroup } from "@mui/material";

export const AvatarGroupCell = ({
  value,
  valueFn,
}: {
  value: string | string[] | null;
  valueFn: (value: string) => string;
}) => {
  if (!value) return <></>;
  else if (typeof value === "string") {
    if (value === "None" || value === "[None]") return <></>;
    else {
      const avatars = JSON.parse(value.replace(/'/g, `"`));
      return (
        <AvatarGroup
          max={6}
          sx={{
            "& .MuiAvatar-root": {
              width: "32px",
              height: "32px",
              zIndex: 1,
            },
          }}
        >
          {avatars.map((userCC: string) => (
            <XAvatar width={28} height={28} value={valueFn(userCC)} />
          ))}
        </AvatarGroup>
      );
    }
  } else {
    return <>N/A</>;
  }
};
