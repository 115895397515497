import { gql } from "@apollo/client";

export const GET_TICKETS = gql`
  query getTickets(
    $query: [String]!
    $assignedTo: String!
    $sort: String!
    $desc: Boolean
  ) {
    getTickets(
      query: $query
      assignedTo: $assignedTo
      sort: $sort
      desc: $desc
    ) {
      data
      next
      previous
      page
      token
      totalPages
      totalResults
      __typename
    }
  }
`;

export const GET_TICKET_STATUSES = gql`
  query {
    getTicketStatuses {
      statuses
      __typename
    }
  }
`;

export const GET_TICKET_TYPES = gql`
  query {
    getTicketTypes {
      types
      __typename
    }
  }
`;

export const GET_TICKET_RESOLUTIONS = gql`
  query {
    getTicketResolutions {
      resolutions
      __typename
    }
  }
`;

export const GET_TICKET_PRIORITIES = gql`
  query {
    getTicketPriorities {
      priorities
      __typename
    }
  }
`;

export const GET_PLATFORM_AREAS = gql`
  query {
    getTicketPlatformAreas {
      platformAreas
      __typename
    }
  }
`;

export const CREATE_NEW_TICKET = gql`
  query createTicket(
    $title: String!
    $desc: String!
    $priority: Int!
    $status: Int!
    $ticketType: Int!
    $platformArea: Int!
    $reporter: String!
    $assignedTo: String!
    $cc: [String]
    $startDate: DateTime
    $dueDate: DateTime
    $duplicateTicketToJira: Boolean!
  ) {
    createTicket(
      title: $title
      desc: $desc
      priority: $priority
      status: $status
      ticketType: $ticketType
      platformArea: $platformArea

      reporter: $reporter
      assignedTo: $assignedTo
      cc: $cc
      startDate: $startDate
      dueDate: $dueDate
      duplicateTicketToJira: $duplicateTicketToJira
    ) {
      result
      __typename
    }
  }
`;

export const UPDATE_TICKET = gql`
  query updateTicket($ticketId: String!, $updates: String!) {
    updateTicket(ticketId: $ticketId, updates: $updates) {
      result
      __typename
    }
  }
`;

export const CREATE_TICKET_COMMENT = gql`
  mutation ($comment: String, $ticketId: String!, $extractOnly: Boolean!) {
    createTicketComment(
      comment: $comment
      ticketId: $ticketId
      extractOnly: $extractOnly
    ) {
      output {
        result
        __typename
      }
      __typename
    }
  }
`;

export const DUPLICATE_TICKET_TO_JIRA = gql`
  query duplicateTicketToJira($ticketId: String!) {
    duplicateTicketToJira(ticketId: $ticketId) {
      result
      __typename
    }
  }
`;

export const GET_WIDGET_CONTENT = gql`
  query Query($widgetsGetWidgetType: String!) {
    widgetsGet(widgetType: $widgetsGetWidgetType) {
      data
      token
    }
  }
`;

export const GET_TICKET = gql`
  query getTicket($ticketId: String, $humanId: String) {
    getTicket(ticketId: $ticketId, humanId: $humanId) {
      result
      __typename
    }
  }
`;
