import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";

export const IconValueCell = ({
  value,
  rowData,
  valueFn,
  iconFn,
  tooltipFn,
  onClick,
}: {
  value: boolean | number | string;
  rowData: Record<string, any>;
  onClick?: () => void;
  valueFn?: (value: boolean | number | string) => string;
  iconFn: (
    value: boolean | number | string,
    rowData: Record<string, any>
  ) => JSX.Element;
  tooltipFn?: (
    value: boolean | number | string,
    rowData: Record<string, any>
  ) => string;
}) => {
  const icon = iconFn(value, rowData);
  const tooltip = tooltipFn?.(value, rowData);
  return tooltip ? (
    <Tooltip title={tooltip} arrow>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pointerEvents: !tooltip ? "none" : "auto",
        }}
        onClick={() => {
          if (!!onClick) onClick();
        }}
      >
        {icon && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "8px",
            }}
          >
            {icon}
          </div>
        )}
        <Typography>{valueFn ? valueFn(value) : value}</Typography>
      </Box>
    </Tooltip>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        pointerEvents: !tooltip ? "none" : "auto",
        height: "100%",
      }}
      onClick={() => {
        if (!!onClick) onClick();
      }}
    >
      {icon && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "8px",
          }}
        >
          {icon}
        </div>
      )}
      <span>{valueFn ? valueFn(value) : value}</span>
    </Box>
  );
};
