import React, { useEffect, useContext, createContext } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { ORIGIN_URL } from "./WithAuth";
import useMenuConfig from "./useMenuConfig";
import { useLocation } from "react-router-dom";

export const UserContext = createContext({});

export function useMe() {
  const user = useContext(UserContext);
  return user;
}

export default function LoginAuth({
  children,
  authInfo,
  keycloak,
  initialized,
  userInfo,
  logout,
}) {
  const {
    menuConfig,
    getUser,
    loading,
    error,
    data,
    parsedMenuOptions,
    appendDynamicMenuItem,
    removeDynamicMenuItem,
    dynamicDashboardPages,
    hasActiveDirectoryInfo,
    appendReportingMenuItem,
    removeReportingMenuItem,
  } = useMenuConfig(false); // true -> uses viewer data mock, false -> use lazy query for viewer
  const location = useLocation();

  useEffect(() => {
    authInfo && authInfo.token && getUser();
    // WARNING: there is no need to add the get user method to the list of dependencies
    // because it will not change unless is executed with the mock
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo]);

  if (keycloak && initialized && authInfo && !authInfo.authenticated) {
    const pathname = location.pathname || "/";
    keycloak.login({
      loginHint: userInfo.email,
      redirectUri: `${ORIGIN_URL}${pathname}`,
    });
    return <LinearProgress />;
  }

  if (authInfo && authInfo.token) {
    if (loading) return <LinearProgress />;
    if (error) {
      console.error("error", error);
    }
    if (!data) return <LinearProgress />;
    return (
      <UserContext.Provider
        value={{
          ...data.viewer,
          rights: JSON.parse(data.viewer?.rights || "[]") || [],
          logout,
          parsedMenuOptions,
          menuConfig,
          appendDynamicMenuItem,
          removeDynamicMenuItem,
          appendReportingMenuItem,
          removeReportingMenuItem,
          dynamicDashboardPages,
          realm: userInfo.realm,
          hasActiveDirectoryInfo,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
  return <LinearProgress />;
}
