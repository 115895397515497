import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery, gql } from "@apollo/client";
import useBoundStore from "../../../store";

const GET_USER = gql`
  {
    viewer {
      id
      firstName
      lastName
      dynamicDashboardPages
      scoutReports
      menuOptions
      isAdmin
      email
      rights
      isJiraEnable
      systemOptions {
        showSearchBar
        showNewDashboardAction
        jiraButtonType
      }
      role {
        id
        name
      }
      defaultDashboard {
        id
        name
      }
    }
  }
`;

const GET_IS_DATA_IN_SYSTEM = gql`
  query getIsDataInSystem {
    isDataInSystem {
      isDataInSystem
      totalDevices
      activeDirectory
    }
  }
`;

const DYNAMIC_DASHBOARDS_TITLE = "My Dashboards";
const DYNAMIC_DASHBOARDS_URL = "new-dashboard";
const SCOUT_DASHBOARDS_TITLE = "Intelligence";

const sortAlphaNum = (a, b) =>
  a?.title.localeCompare(b?.title, "en", { numeric: true });

function buildDynamicMenu(
  dynamicPages: {
    pageid: string;
    title: string;
    is_default?: string;
    icon?: string;
  }[],
  isPortalRole?: boolean
): {
  title: string;
  icon: string;
  submenu: { title; page; pageid?; isDefault?; icon?: string }[];
} {
  const builtPages = dynamicPages
    .sort(sortAlphaNum)
    .map((page) => ({
      title: page.title,
      pageid: page.pageid,
      page: "dynamic/" + page.pageid,
      isDefault: page.is_default,
      icon: page.icon,
    }))
    .filter((page) => page.page !== DYNAMIC_DASHBOARDS_URL && page.pageid);

  return {
    title: DYNAMIC_DASHBOARDS_TITLE,
    icon: "fa fa-chart-simple-horizontal",
    submenu: [
      ...(!isPortalRole
        ? [
            {
              page: DYNAMIC_DASHBOARDS_URL,
              title: "New Dashboard",
              icon: "fa fa-plus fa-lg",
            },
          ]
        : []),
      ...builtPages,
    ],
  };
}

function buildScoutMenu(
  scoutPages: {
    pageid: string;
    title: string;
    type?: string;
    is_default?: boolean;
    icon?: string;
  }[],
  isPortalRole?: boolean
): {
  dashboardPages: Array<{
    title: string;
    pageid: string;
    page: string;
    icon?: string;
  }>;
  reportPages: Array<{
    title: string;
    pageid: string;
    page: string;
    icon?: string;
  }>;
} {
  const dashboardPages = scoutPages
    .filter((page) => page.type === "dashboardlayout")
    .sort(sortAlphaNum)
    .map((page) => ({
      title: page.title,
      pageid: page.pageid,
      page: "intelligence-dashboards/" + page.pageid,
      icon: page.icon,
    }));

  const reportPages = scoutPages
    .filter((page) => page.type === "dashboardreports")
    .sort(sortAlphaNum)
    .map((page) => ({
      title: page.title,
      pageid: page.pageid,
      page: "intelligence-reports/" + page.pageid,
      icon: page.icon,
    }));

  return {
    dashboardPages,
    reportPages,
  };
}

function parseMenuOptions(userData, haveValidData, isPortalRole) {
  if (userData && userData.viewer && userData.viewer.menuOptions) {
    try {
      const menuOptionsObj = JSON.parse(userData.viewer.menuOptions);
      if (!haveValidData) {
        menuOptionsObj.aside = {
          items: [],
        };
      }

      if (userData.viewer.dynamicDashboardPages) {
        const dynamicPages: any[] = JSON.parse(
          userData.viewer.dynamicDashboardPages
        );

        if (dynamicPages && dynamicPages.length) {
          // remove new-dashboard menu entry if exists
          menuOptionsObj.aside.items = menuOptionsObj.aside.items.filter(
            (item) => item.title !== DYNAMIC_DASHBOARDS_TITLE
          );
          // create the dynamic pages menu
          const dynamicDashboardMenu = buildDynamicMenu(
            dynamicPages.map((page) => ({
              title: page.page,
              pageid: page.pageid,
              is_default: page.is_default,
              icon: page.icon,
            })),
            isPortalRole
          );

          // add the dynamic menu as the first section item in the sidebar
          menuOptionsObj.aside.items = [
            dynamicDashboardMenu,
            ...menuOptionsObj.aside.items,
          ];
        }
      }
      if (userData.viewer.scoutReports) {
        const scoutPages: any[] = JSON.parse(userData.viewer.scoutReports);

        if (scoutPages && scoutPages.length) {
          // create the scout pages menu
          const { dashboardPages, reportPages } = buildScoutMenu(
            scoutPages.map((page) => ({
              title: page.Title,
              pageid: page.page,
              is_default: page.is_default,
              type: page.type,
              icon: page.icon,
            })),
            true
          );

          // update scout reports menu entry
          menuOptionsObj.aside.items = menuOptionsObj.aside.items.map((item) =>
            item.title !== SCOUT_DASHBOARDS_TITLE
              ? item
              : {
                  ...item,
                  submenu: item.submenu.map((m) =>
                    m.title === "Dashboards"
                      ? {
                          ...m,
                          submenu: [
                            ...(m.submenu || []).map((mp) => ({
                              title: mp.title,
                              pageid: mp.page,
                              page: mp.page,
                              icon: mp.icon,
                            })),
                            ...dashboardPages,
                          ],
                        }
                      : m.title === "Reports"
                      ? {
                          ...m,
                          submenu: [...(m.submenu || []), ...reportPages],
                        }
                      : m
                  ),
                }
          );
        }
      }

      return menuOptionsObj;
    } catch (error) {
      console.error("ERROR: couldn't parse menu.");
    }
  }
  return {};
}

function selectDefaultRoute(menuConfig) {
  function addToPages(pages, item) {
    if (item.page) {
      pages.push(item);
    }
  }
  if (menuConfig && menuConfig.aside && menuConfig.aside.items) {
    const pages = [];

    menuConfig.aside.items.forEach((entry) => {
      if (entry.submenu) {
        entry.submenu.forEach((item) => addToPages(pages, item));
      } else {
        addToPages(pages, entry);
      }
    });
    const firstPageInMenu: any = pages[0];
    if (firstPageInMenu) {
      return firstPageInMenu.page;
    }
  }

  // this will only happen if there is no data but there is a admin menu
  if (menuConfig && menuConfig.admin && menuConfig.admin.items) {
    const pages = [];

    menuConfig.admin.items.forEach((entry) => {
      if (entry.submenu) {
        entry.submenu.forEach((item) => addToPages(pages, item));
      } else {
        addToPages(pages, entry);
      }
    });
    const firstPageInMenu: any = pages[0];
    if (firstPageInMenu) {
      return firstPageInMenu.page;
    }
  }

  // this happen if there is no menu pages to iterate
  return "loading";
}

function getDynamicMenuListFromMenuOptions(resultMenuOptions) {
  let dynamicMenuItems = resultMenuOptions.aside.items.filter(
    (section) => section.title === DYNAMIC_DASHBOARDS_TITLE
  )[0];
  const dynamicMenuList = (dynamicMenuItems || { submenu: [] }).submenu;
  return dynamicMenuList;
}

function useMenuConfig(useMockData = false) {
  const setCurrentUser = useBoundStore((state) => state.setCurrentUser);
  const [getUser, { loading, error, data }] = useLazyQuery(GET_USER);

  const [getIsDataInSystem, { data: isDataInSystem }] = useLazyQuery(
    GET_IS_DATA_IN_SYSTEM
  );
  const history = useHistory();
  const [menuConfig, setMenuConfig] = useState<any>({
    aside: { items: [] },
    defaultPage: "loading",
  });
  const [dynamicDashboardPages, setDynamicDashboardPages] = useState<string[]>(
    []
  );
  const [parsedMenuOptions, setParsedMenuOptions] = useState<any>({});
  const [validDataToShowMenu, setValidDataToShowMenu] = useState<boolean>();
  const [hasActiveDirectoryInfo, setHasActiveDirectoryInfo] =
    useState<boolean>();

  useEffect(() => {
    try {
      if (parsedMenuOptions?.aside) {
        const defaultPage = selectDefaultRoute(parsedMenuOptions);
        const userMenu = {
          aside: {
            items: [
              ...parsedMenuOptions.aside.items.filter((item) => !item.hidden),
            ],
          },
          defaultPage,
          admin: { items: parsedMenuOptions?.admin?.items || [] },
        };
        setMenuConfig({ ...userMenu });
      }
    } catch (error) {
      console.error("ERROR: No menu for user", error);
    }
  }, [parsedMenuOptions]);

  useEffect(() => {
    getIsDataInSystem();
  }, [getIsDataInSystem]);

  useEffect(() => {
    const isValid = isDataInSystem?.isDataInSystem?.isDataInSystem;
    const hasActiveDirectory =
      !!isDataInSystem?.isDataInSystem?.activeDirectory;
    setValidDataToShowMenu(isValid);
    setHasActiveDirectoryInfo(hasActiveDirectory);
  }, [isDataInSystem]);

  useEffect(() => {
    try {
      if (data) {
        setCurrentUser({
          id: data.viewer?.id,
          email: data.viewer?.email,
          firstName: data.viewer?.firstName,
          lastName: data.viewer?.lastName,
          isJiraEnable: data.viewer?.isJiraEnable,
          rights: data.viewer?.rights ? JSON.parse(data.viewer?.rights) : [],
          systemOptions: data.viewer?.systemOptions,
        });
        const resultMenuOptions = parseMenuOptions(
          data,
          validDataToShowMenu,
          !data.viewer?.systemOptions.showNewDashboardAction
        );
        const dynamicMenuList =
          getDynamicMenuListFromMenuOptions(resultMenuOptions);
        setParsedMenuOptions({ ...resultMenuOptions });
        setDynamicDashboardPages(dynamicMenuList);
      }
    } catch (error) {
      console.error("ERROR: couldn't parse menu", error);
    }
  }, [data, validDataToShowMenu, setCurrentUser]);

  function filteredMenus() {
    let dynamicMenuItems = parsedMenuOptions.aside.items.filter(
      (section) => section.title === DYNAMIC_DASHBOARDS_TITLE
    )[0];

    const menuWithoutDynamic = parsedMenuOptions.aside.items.filter(
      (section) => section.title !== DYNAMIC_DASHBOARDS_TITLE
    );

    return {
      dynamicMenuItems,
      menuWithoutDynamic,
      adminMenu: parsedMenuOptions.admin,
    };
  }

  function buildParsedMenu(dynamicMenuList, menuWithoutDynamic, adminMenu) {
    const dynamicDashboardMenu = buildDynamicMenu(dynamicMenuList);
    const newParsedMenuOptions = {
      aside: {
        items: [dynamicDashboardMenu, ...menuWithoutDynamic],
      },
      admin: adminMenu,
    };
    return newParsedMenuOptions;
  }

  function appendDynamicMenuItem(pageId, pageTitle, isDefault = false) {
    const { dynamicMenuItems, menuWithoutDynamic, adminMenu } = filteredMenus();

    let dynamicMenuList: any[] = [
      ...(dynamicMenuItems || { submenu: [] }).submenu,
    ];
    if (!dynamicMenuItems?.submenu?.map((sm) => sm.pageid).includes(pageId)) {
      dynamicMenuList = [
        {
          title: pageTitle,
          pageid: pageId,
          page: "dynamic/" + pageId,
          isDefault,
        },
        ...(dynamicMenuItems || { submenu: [] }).submenu,
      ];
    } else {
      dynamicMenuList = dynamicMenuList.map((sm) =>
        sm.pageid === pageId ? { ...sm, title: pageTitle, isDefault } : sm
      );
    }

    // create the dynamic pages menu
    const newParsedMenuOptions = buildParsedMenu(
      dynamicMenuList,
      menuWithoutDynamic,
      adminMenu
    );

    setParsedMenuOptions(newParsedMenuOptions);
    setDynamicDashboardPages(dynamicMenuList);
    setTimeout(() => {
      history.push(`/dynamic/${pageId}`);
    }, 500);
  }

  function removeDynamicMenuItem(pageId) {
    const { dynamicMenuItems, menuWithoutDynamic, adminMenu } = filteredMenus();

    const dynamicMenuList = [
      ...dynamicMenuItems.submenu.filter((item) => item.pageid !== pageId),
    ];

    if (dynamicMenuList.length > 0) {
      // create the dynamic pages menu
      const newParsedMenuOptions = buildParsedMenu(
        dynamicMenuList,
        menuWithoutDynamic,
        adminMenu
      );

      setParsedMenuOptions(newParsedMenuOptions);
    } else {
      const newParsedMenuOptions = {
        aside: {
          items: [...menuWithoutDynamic],
        },
      };
      setParsedMenuOptions(newParsedMenuOptions);
    }
    history.push(`/${DYNAMIC_DASHBOARDS_URL}`);
    setDynamicDashboardPages(dynamicMenuList);
  }

  function appendReportingMenuItem(pageId, pageTitle) {
    const dashboardMenuItem = parsedMenuOptions.aside.items
      .filter((section) => section.title === SCOUT_DASHBOARDS_TITLE)[0]
      .submenu.filter((sm) => sm.title === "Dashboards")[0];
    let dashboardMenuList: any[] = [
      ...(dashboardMenuItem || { submenu: [] }).submenu,
    ];
    if (!dashboardMenuItem?.submenu?.map((sm) => sm.pageid).includes(pageId)) {
      dashboardMenuList = [
        {
          title: pageTitle,
          pageid: pageId,
          page: "intelligence-dashboards/" + pageId,
        },
        ...(dashboardMenuItem || { submenu: [] }).submenu,
      ];
    } else {
      dashboardMenuList = dashboardMenuList.map((sm) =>
        sm.pageid === pageId ? { ...sm, title: pageTitle } : sm
      );
    }

    setParsedMenuOptions((prev) => {
      return {
        ...prev,
        aside: {
          ...prev.aside,
          items: prev.aside.items.map((section) =>
            section.title === SCOUT_DASHBOARDS_TITLE
              ? {
                  ...section,
                  submenu: section.submenu.map((sm) =>
                    sm.title === "Dashboards"
                      ? {
                          ...sm,
                          submenu: dashboardMenuList,
                        }
                      : sm
                  ),
                }
              : section
          ),
        },
      };
    });
  }

  function removeReportingMenuItem(pageId) {
    const dashboardMenuItem = parsedMenuOptions.aside.items
      .filter((section) => section.title === SCOUT_DASHBOARDS_TITLE)[0]
      .submenu.filter((sm) => sm.title === "Dashboards")[0];
    const dashboardMenuList: any[] = [
      ...dashboardMenuItem.submenu.filter((item) => item.pageid !== pageId),
    ];

    setParsedMenuOptions((prev) => {
      return {
        ...prev,
        aside: {
          ...prev.aside,
          items: prev.aside.items.map((section) =>
            section.title === SCOUT_DASHBOARDS_TITLE
              ? {
                  ...section,
                  submenu: section.submenu.map((sm) =>
                    sm.title === "Dashboards"
                      ? {
                          ...sm,
                          submenu: dashboardMenuList,
                        }
                      : sm
                  ),
                }
              : section
          ),
        },
      };
    });
  }

  return {
    menuConfig,
    getUser,
    loading,
    error,
    data,
    parsedMenuOptions,
    appendDynamicMenuItem,
    removeDynamicMenuItem,
    dynamicDashboardPages,
    hasActiveDirectoryInfo,
    appendReportingMenuItem,
    removeReportingMenuItem,
  };
}

export default useMenuConfig;
