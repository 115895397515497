import React, { useCallback, useMemo } from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "./Drawer";
import DrawerHeader from "./DrawerHeader";
import MenuNavList from "./MenuNavList";
// import MenuNavSetting from "./MenuNavSetting";
import TopLogo from "./TopLogo";
import BottomLogo from "./BottomLogo";
import useBoundStore from "../../store";
import { useMe } from "../../app/pages/auth/LoginAuth";
import {
  ScoutReportIcon,
  SiderMyDarsboardIcon,
  SiderIntelligenceIcon,
  SiderAttackPathIcon,
  SiderAssetToolsIcon,
  SiderIdentityToolsIcon,
  SiderTicketManagementIcon,
  SiderDocumentsIcon,
  SiderSynapseEngineIcon,
  SiderSourceManagementIcon,
  SiderUserManagementIcon,
  SiderPredictiveAnalyticsIcon,
  SiderReportIcon,
  SiderPathFinderIcon,
  SiderImpactMatrixIcon,
  SiderVulnerabilitiesIcon,
  SiderRogueReportIcon,
  SiderInventoryIcon,
  SiderActiveDirectory,
  SiderTicketsIcon,
  SiderShareIcon,
  SiderAgentControls,
  SiderUserAdministrationIcon,
  SiderRolesAdministrationIcon,
  SiderGroupsAdministrationIcon,
  SiderConfigurationIcon,
} from "../../components-v2/icons";
import { TListItem } from "../../components-v2/MenuList";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import routes from "../../config/page-route";

import layoutConfig from "../layout-config.json";

// This needs API changes.
const menuIcons = {
  "My Dashboards": <SiderMyDarsboardIcon />,
  Intelligence: <SiderIntelligenceIcon />,
  "Predictive Anatytics": <SiderPredictiveAnalyticsIcon />,
  "Early Warnings": <SiderPredictiveAnalyticsIcon />,
  Dashboards: <SiderMyDarsboardIcon />,
  Reports: <SiderReportIcon />,
  "Attack Path Tools": <SiderAttackPathIcon />,
  "Path Finder": <SiderPathFinderIcon />,
  "Impact Matrix": <SiderImpactMatrixIcon />,
  Vulnerabilities: <SiderVulnerabilitiesIcon />,
  "Asset Tools": <SiderAssetToolsIcon />,
  "Rogue Report": <SiderRogueReportIcon />,
  Inventory: <SiderInventoryIcon />,
  "Identity Tools": <SiderIdentityToolsIcon />,
  "Active Directory": <SiderActiveDirectory />,
  "Tickets Management": <SiderTicketManagementIcon />,
  Tickets: <SiderTicketsIcon />,
  Documents: <SiderDocumentsIcon />,
  Shared: <SiderShareIcon />,
  "Synapse Engine": <SiderSynapseEngineIcon />,
  "Agent Controls": <SiderAgentControls />,
  "User Management": <SiderUserManagementIcon />,
  "User Administration": <SiderUserAdministrationIcon />,
  "Roles Administration": <SiderRolesAdministrationIcon />,
  "Groups Administration": <SiderGroupsAdministrationIcon />,
  "Source Management": <SiderSourceManagementIcon />,
  Configuration: <SiderConfigurationIcon />,
  "Scout Engine": (
    <i className="fa fa-border-none fa-2x" style={{ color: "white" }} />
  ),
  "Scout Reports": <ScoutReportIcon />,
};

const convertMenuItems = (items: any) => {
  if (!items.length) return [];
  return items.map((item) => ({
    label: item.title || "Untitled",
    value:
      item.title === "Key Performance Metrics"
        ? `intelligence-static/${item.page}`
        : item.page || item.title,
    ...(menuIcons[item.title]
      ? { icon: menuIcons[item.title] }
      : item.icon && {
          icon: (
            <i className={`${item.icon} fa-2x`} style={{ color: "white" }} />
          ),
        }),
    ...(item.badge && { badge: item.badge }),
    ...(item.submenu?.length && {
      children:
        item.title === "Scout Reports"
          ? convertMenuItems(item.submenu).map((sb) => ({
              ...sb,
              value: `scout-report/${sb.value}`,
            }))
          : convertMenuItems(item.submenu),
    }),
  }));
};

const findMatchedMenuItem = (items: any, pathname: string) => {
  for (const item of items) {
    if (pathname.includes(`/${item.value}`)) return item;
    if (item.children?.length) {
      const result = findMatchedMenuItem(item.children, pathname);
      if (result) return result;
    }
  }
  return null;
};

const Sider = () => {
  const { updateConfig, config } = useBoundStore((state) => state);
  const me: any = useMe();
  const history = useHistory();
  const location = useLocation();

  const dynamicMenus = useMemo(() => {
    if (!me.menuConfig) return [];
    const asideMenus = convertMenuItems(me.menuConfig.aside?.items);
    const adminMenus = convertMenuItems(me.menuConfig.admin?.items);
    const menus = [...asideMenus, ...adminMenus];
    return menus;
  }, [me.menuConfig]);

  const currentMenuItem = useMemo(() => {
    const item = findMatchedMenuItem(dynamicMenus, location.pathname);
    if (!item && !!history.location.state?.prevPath) {
      return findMatchedMenuItem(dynamicMenus, history.location.state.prevPath);
    }
    return item;
  }, [location.pathname, history.location.state, dynamicMenus]);

  const onDrawerExpand = useCallback(() => {
    updateConfig();
  }, [updateConfig]);

  const onNavItemClick = useCallback(
    (navItem: TListItem) => {
      console.log("=====================", navItem);
      if (
        routes.find((route) =>
          matchPath(`/${navItem.value}`, {
            path: route.path,
            exact: true,
            strict: false,
          })
        )
      ) {
        history.push(`/${navItem.value}`);
      }
    },
    [history]
  );
  return (
    <Drawer variant="permanent" open={config.siderExpanded}>
      <DrawerHeader expanded={config.siderExpanded}>
        <TopLogo expanded={config.siderExpanded} />
      </DrawerHeader>
      <Box
        sx={{
          height: `calc(100% - ${
            config.siderExpanded
              ? layoutConfig.extendedLogoHeight
              : layoutConfig.topNavbarHeight
          }px)`,
          display: "flex",
          flexDirection: "column",
          bgcolor: "primary.main",
        }}
      >
        {/* {config.siderExpanded && (
          <XInput
            placeholder="Search"
            sxProps={{ m: 2, bgcolor: "primary.light", border: "none" }}
            startAdornment={<SearchIcon />}
            value={menuText}
            onChange={(e) => setMenuText(e.target.value)}
            clearable
            onClear={() => setMenuText("")}
          />
        )} */}
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <MenuNavList
            siderExpanded={config.siderExpanded}
            list={dynamicMenus}
            selectedItem={currentMenuItem || dynamicMenus[0]}
            onSelectedItem={onNavItemClick}
          />
        </Box>
        {/* <MenuNavSetting
          siderExpanded={config.siderExpanded}
          list={navSettings}
          selectedItem={{ label: "Foo", value: "Foo" }}
          onSelectedItem={() => {}}
        /> */}
        <BottomLogo expanded={config.siderExpanded} />
      </Box>
      <Box
        sx={{
          position: "fixed",
          top: "53%",
          transform: "translate(35%, -50%)",
          left: config.siderExpanded
            ? layoutConfig.drawerWidth
            : layoutConfig.condensedDrawerWidth,
          width: 14,
          height: 148,
          borderRadius: 4,
          bgcolor: "grey.600",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          ":hover": {
            bgcolor: "grey.500",
          },
        }}
        onClick={onDrawerExpand}
      >
        {config.siderExpanded ? (
          <KeyboardArrowLeft sx={{ color: "primary.light", fontSize: 28 }} />
        ) : (
          <KeyboardArrowRight sx={{ color: "primary.light", fontSize: 28 }} />
        )}
      </Box>
    </Drawer>
  );
};

export default Sider;
