import { hexBootstrapColors } from "../variables";
import * as d3 from "d3";

export const isNumeric = (num) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num);

export const isEmpty = (obj) => {
  if (obj === null || obj === undefined) return true;
  if (Array.isArray(obj)) {
    return obj.length ? false : true;
  }
  if (typeof obj === "string" && obj === "{}") return true;
  if (typeof obj === "object") {
    for (const prop in obj) {
      if (obj[prop]) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const valueSum = (obj) =>
  typeof obj === "number"
    ? obj
    : isEmpty(obj) || typeof obj !== "object"
    ? 0
    : Object.values(obj).reduce((a, c) => a + +c, 0);

export const coloredValue = (number) => {
  if (number[number.length - 1] === "%") {
    const realNumber = parseInt(number);
    return realNumber > 90
      ? "success.main"
      : realNumber > 70
      ? "warning.main"
      : "error.main";
  }
  return "text.secondary";
};

export const riskLevelColor = (riskLevel) => {
  const upperRiskLevel = (riskLevel || "").toUpperCase();
  if (upperRiskLevel === "CRITICAL") return hexBootstrapColors[5];
  if (upperRiskLevel === "HIGH") return hexBootstrapColors[2];
  if (upperRiskLevel === "MEDIUM") return hexBootstrapColors[0];
  if (upperRiskLevel === "LOW") return hexBootstrapColors[9];
  return "grey";
};

export const randomRange = (min, max) =>
  Math.floor(Math.random() * (max - min) + min);

export const uniqueObjArray = (array = [], uniqueBy) =>
  !array.length
    ? []
    : [...new Map(array.map((item) => [item[uniqueBy], item])).values()];

export function maxOrderLevel(nodes) {
  return d3.max(nodes, (d) => d.order_level);
}

export const constructNodes = (nodes, step) => {
  if (!nodes || !nodes.length) return [];
  const maxLevel = maxOrderLevel(nodes);
  let updatedNodes = [];
  for (let i = 0; i <= maxLevel; i++) {
    let sameLevelNodes = nodes.filter((bn) => bn.order_level === i);
    const initCYPos = (-(sameLevelNodes.length - 1) / 2) * step * 2;
    sameLevelNodes = sameLevelNodes.map((sln, j) => ({
      ...sln,
      // cx0: i * config.step * 2,
      cx: i * step * 2,
      // cy0: initCYPos + j * config.step * 2,
      cy: initCYPos + j * step * 2,
      dist: 0,
      expanded: false,
    }));
    updatedNodes.push(...sameLevelNodes);
  }

  return updatedNodes;
};

export const colorFn = (data) =>
  d3.scaleOrdinal().domain(Object.keys(data).sort()).range(d3.schemePaired);

export function sortByDate(data) {
  if (!data?.length) return [];
  return data.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
}

export function numericFormat(value, decimals = 0) {
  return (Number(value) || 0).toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export const getAvatar = (fullName) => {
  if (!fullName) return "?";
  const splited = fullName.split(" ");
  if (splited.length < 2) {
    return splited[0].substring(0, 1).toUpperCase();
  }
  return (
    splited[0].substring(0, 1).toUpperCase() +
    splited[1].substring(0, 1).toUpperCase()
  );
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return string; // Return if the string is empty or null
  return string.charAt(0).toUpperCase() + string.slice(1);
};
