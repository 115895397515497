import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  List,
  Collapse,
  ListItem,
  MenuItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip,
  Paper,
  Popper,
  ClickAwayListener,
  Typography,
} from "@mui/material";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

export type TListItem = {
  label: string;
  value: string;
  icon?: JSX.Element;
  badge?: {
    background_color: string;
    foreground_color: string;
    title: string;
  };
  children?: TListItem[];
};

export type TMenuList = {
  list: TListItem[];
  selectedItem: TListItem;
  onSelectedItem?: (item: TListItem) => void;
  siderExpanded?: boolean;
};

const MenuList = ({
  list = [],
  selectedItem,
  siderExpanded = true,
  onSelectedItem,
}: TMenuList) => {
  const [expandItems, setExpandItems] = useState<string[]>([]);
  const [focusedItem, setFocusedItem] = useState<TListItem | null>(null);
  const [anchorEl, setAnchorEl] = useState<
    HTMLDivElement | HTMLLIElement | null
  >(null);

  const onItemClick = useCallback(
    (
      evt: React.MouseEvent<HTMLDivElement | HTMLLIElement, MouseEvent>,
      item: TListItem
    ) => {
      if (item.children?.length) {
        if (siderExpanded) {
          setExpandItems((prev) =>
            prev.includes(item.value)
              ? prev.filter((value) => value !== item.value)
              : [...prev, item.value]
          );
        } else {
          setFocusedItem(item);
          setAnchorEl(evt.currentTarget);
        }
      } else {
        setAnchorEl(null);
        onSelectedItem?.(item);
      }
    },
    [onSelectedItem, siderExpanded]
  );

  const renderList = useCallback(
    (listItem: TListItem, submenu: boolean, subLevel: number) => {
      const selected =
        selectedItem.value === listItem.value ||
        (!siderExpanded &&
          !!focusedItem &&
          focusedItem.value === listItem.value);

      var menu = (
        <ListItem
          key={listItem.value}
          disablePadding
          sx={{
            display: "block",
          }}
        >
          <ListItemButton
            selected={selected}
            onClick={(event) => onItemClick(event, listItem)}
            sx={{
              borderRadius: 1,
              justifyContent: siderExpanded ? "initial" : "center",
              mx: 0,
              pl: subLevel,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: siderExpanded ? 3 : "auto",
                ml: siderExpanded ? 1 : "50%",
                justifyContent: "center",
              }}
            >
              {listItem.icon ? listItem.icon : <Box sx={{ width: 24 }} />}
            </ListItemIcon>
            {siderExpanded && (
              <ListItemText
                primary={listItem.label}
                sx={{
                  lineHeight: "1",
                  overflow: "hidden",
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  "&>.MuiTypography-root": {
                    fontSize: submenu ? 16 : 18,
                    fontWeight: !submenu || selected ? 700 : 500,
                  },
                  color:!submenu?"$F5F5F6": listItem.children?.length?"$F5F5F6":
                  selectedItem?.value === listItem.value
                    ? "#F5F5F6"
                    : "rgba(255, 255, 255, 0.38)",
                    "&:hover":{
                      color: "#F5F5F6",
                    }
                }}
              />
            )}
            {!!listItem.badge && siderExpanded && (
              <Chip
                size="small"
                label={listItem.badge.title}
                sx={{
                  bgcolor: listItem.badge.background_color,
                  color: listItem.badge.foreground_color,
                  fontSize: "11px",
                }}
              />
            )}
            {siderExpanded && listItem.children?.length ? (
              expandItems.includes(listItem.value) ? (
                <ArrowDropUp sx={{ color: "#3E4784" }} />
              ) : (
                <ArrowDropDown sx={{ color: "#3E4784" }} />
              )
            ) : (
              <></>
            )}
          </ListItemButton>
          {listItem.children?.length && siderExpanded && (
            <Collapse
              key={listItem.value}
              in={expandItems.includes(listItem.value)}
              timeout="auto"
              unmountOnExit
            >
              <List>
                {listItem.children.map((child: TListItem) =>
                  renderList(child, true, subLevel + 2)
                )}
              </List>
            </Collapse>
          )}
        </ListItem>
      );
      if (!submenu) {
        menu = (
          <Box
            key={listItem.value}
            sx={{
              margin: siderExpanded ? "6px 16px" : "0px 16px",

              borderRadius: "10px",
              cursor: "pointer",
              bgcolor:
                siderExpanded &&
                listItem.children?.length &&
                expandItems.includes(listItem.value)
                  ? "#161B26"
                  : "primary.main",
            }}
          >
            {menu}
          </Box>
        );
      }
      return menu;
    },
    [expandItems, selectedItem, siderExpanded, focusedItem, onItemClick]
  );

  useEffect(() => {
    setFocusedItem(null);
    setExpandItems([]);
  }, [siderExpanded]);

  return (
    <Box sx={{ width: "100%" }}>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Box>
          {list.length ? (
            <List>
              {list.map((item: TListItem) => renderList(item, false, 0))}
            </List>
          ) : (
            <Box p={4} color="primary.light">
              No data available
            </Box>
          )}

          {!!focusedItem && (
            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              placement="right-start"
              sx={{
                zIndex: 1300,
                "&.MuiPopper-root": {
                  zIndex: 1300,
                },
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  marginLeft: 2,
                  backgroundImage: "none",
                  bgcolor: "primary.dark",
                  border: "1px solid",
                  borderColor: "primary.light",
                }}
              >
                <Typography
                  mx={1}
                  my={0.5}
                  variant="subtitle2"
                  fontWeight="bold"
                >
                  {focusedItem.label}
                </Typography>
                <Box
                  sx={{
                    p: 1,
                    borderTop: "1px solid",
                    borderColor: "primary.light",
                    maxHeight: 320,
                    overflowY: "auto",
                  }}
                >
                  {focusedItem!.children!.map((child: TListItem) => (
                    <MenuItem
                      key={child.value}
                      selected={selectedItem.value === child.value}
                      onClick={(event) => {
                        event.stopPropagation();
                        onItemClick(event, child);
                      }}
                      sx={{
                        borderRadius: 1,
                      }}
                    >
                      {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr : 2,
                          justifyContent: "center",
                        }}
                      >
                        {child.icon ? (
                          child.icon
                        ) : (
                          <Box sx={{ width: 24 }} />
                        )}
                      </ListItemIcon> */}
                      <ListItemText
                        primary={child.label}
                        sx={{
                          opacity: 1,
                          "&>.MuiTypography-root": { fontSize: 12 },
                        }}
                      />
                      {!!child.badge && (
                        <Chip
                          size="small"
                          label={child.badge.title}
                          sx={{
                            ml: 2,
                            bgcolor: child.badge.background_color,
                            color: child.badge.foreground_color,
                            fontSize: 9,
                          }}
                        />
                      )}
                    </MenuItem>
                  ))}
                </Box>
              </Paper>
            </Popper>
          )}
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

export default MenuList;
